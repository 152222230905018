@import './AnnotationBaseStyles.scss';
@import '../../../material-design/MaterialDesign';

.dot-touch-area {
	@extend .annotation-visible-touch-area;
	fill: material-color(primary, accent);
	stroke: none;

	&.hidden {
		fill: transparent;
	}
}
