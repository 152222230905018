@import '../MaterialDesign';

$indicator-text-color: material-foreground-color();

.material-indicator {
	background-color: transparent;
	color: inherit;

	&--bubble {
		display: inline-block;

		min-width: 12px;
		border-radius: 5px;
		padding: 2px;
		background-color: currentColor;

		@include material-font( body1 );
		text-align: center;

		width: 100%;
		&-text {
			color: $indicator-text-color;
		}
	}

	&--needle {
		width: 0;
		height: 0;

		border-color: currentColor transparent transparent transparent;
		border-width: 5px 5px 0 5px;
		border-style: solid;
	}
}
