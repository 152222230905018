@import '../../material-design/MaterialDesign';
@import '../../material-design/components/Divider';

.webview-sidebar-header {
	display: none;

	align-items: center;
	flex-direction: row;

	background-color: theme-color(paper);
	color: theme-color(primary-text);
	fill: theme-color(primary-text);
	justify-content: space-between;

	@include material-divider();

	&-visible {
		display: flex;
	}

	.toggle-button.MuiCheckbox-root {
		color: material-color(primary, base);
		transform: rotate(-90deg);
	}
}
