@use "sass:math";

@import './DeviceTypes.scss';

$material-grid-definitions: (
	xs: (
		screen-size: screen-size-between-rule(extra-small, extra-small),
		columns: 4,
		gutter: 16px,
		margin: 16px
	),
	sm: (
		screen-size: screen-size-between-rule(small, small),
		columns: 8,
		gutter: 16px,
		margin: 16px
	),
	lg: (
		screen-size: screen-size-between-rule(medium, large),
		columns: 12,
		gutter: 24px,
		margin: 24px
	),
	xl: (
		screen-size: screen-size-between-rule(extra-large, extra-large),
		columns: 12,
		gutter: 24px,
		margin: 24px
	),
	xxl: (
		screen-size: screen-size-between-rule(extra-extra-large, extra-extra-large),
		columns: 12,
		gutter: 24px,
		margin: 24px
	)
);

@mixin material-content-height {
	min-height: 48px;
}

@mixin material-gutter {
	@each $type, $definition in $material-grid-definitions {
		@media screen and #{map-get($definition, screen-size)} {
			$gutter: map-get($definition, gutter);
			padding-left: $gutter;
			padding-right: $gutter;
		}
	}
}

@mixin material-grid-row-padding {
	@each $type, $definition in $material-grid-definitions {
		@media screen and #{map-get($definition, screen-size)} {
			$gutter: map-get($definition, gutter);
			$margin: map-get($definition, margin);

			padding: 0 $gutter - $margin 0 $gutter;
		}
	}
}

@mixin material-column-base( $margin ) {
	box-sizing: border-box;
	display: flex;
	align-items: center;

	padding: 0 $margin 0 0;
}

@mixin material-column($total-number-columns, $column-width) {
	width: math.div(100%, $total-number-columns) * $column-width;
}
