@import '../../../material-design/MaterialDesign';
@import '../../../material-design/components/Sidebar';

@import '../../../Helpers.scss';

.app-bar-sidebar-extension-area {
	display: flex;
	align-items: center;

	box-sizing: border-box;
	border-left: 1px solid theme-color(divider);

	@include fixed-width($desktop-sidebar-width+1);
	@include mobile() {
		@include fixed-width($mobile-sidebar-width+1);
	}
}
