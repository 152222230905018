@import '../../material-design/DeviceTypes';

.emergency-patient-link {
	padding: 8px;
	> * {
		width: 100%;
		@include screen-size-between(medium, extra-large) {
			width: initial;
		}
	}

}
