.size-aware-component-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	pointer-events: none;
	background: transparent;
	opacity: 0;
}