@import '../../Helpers.scss';
@import '../../material-design/Colors';
@import '../../material-design/DeviceTypes';

@import '../../../constants/ShareReceiverListConstants.json';

.share-receiver-list-item {
	@include fixed-height(#{$list-item-height}px);

	padding-right: 24px;
	@include mobile-small() {
		padding-right: 16px;
	}

	&.interactive {
		cursor: pointer;
		&:hover {
			background: theme-color(background);
		}
	}

	&.selected {
		background: material-color(primary, lighter);
	}
}