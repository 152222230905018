@import '../MaterialDesign';

$selection-color: #F5F5F5;
$hover-color: #EEEEEE;

.material-date-picker--container {
	@include material-font(caption);
}

.material-date-picker--combo-boxes {
	.material-button {
		height: 24px;
	}

	.button.flat {
		padding: 0;
		color: inherit;
	}

	select {
		background-color: transparent;
		border: none;
		color: inherit;
		@include material-font(caption);
	}
}

.material-date-picker--calendar {
	th {
		color: theme-color(disabled-text);
		font-weight: normal;
	}

	td {
		border-radius: 100%;
	}

	td.in-range {
		background-color: $selection-color;
	}

	td:hover, td.in-range:hover {
		background-color: $hover-color;
	}

	td.selected, td.selected:hover {
		background-color: material-color(primary);
	}

	td.other-month {
		color: theme-color(disabled-text);
		visibility: hidden;
		pointer-events: none;
	}

	td, th {
		width: 24px;
		height: 24px;
		text-align: center;
	}
}
