@import '../../../material-design/MaterialDesign';

.viewer-popup-help {
	color: theme-color(primary-text);

	.topic {
		padding-top: 10px;
		&:first-child {
			padding-top: 0;
		}

		ul {
			margin: 5px 0 0 0;
		}
	}

	.warning-caption {
		display: flex;
		align-items:center;

		@include material-font(title);
		svg {
			fill: currentColor;
		}
	}

	.caption {
		display: block;
		@include material-font(title);

		padding-top: 10px;
		&:first-child {
			padding-top: 0;
		}
	}

	.description {
		@include material-font(body1);
	}

	section {
		padding-top: 16px;
		&:first-child {
			padding-top: 0;
		}
	}
}
