@import '../MaterialDesign';

@mixin material-divider( $width: 1px, $border-side: 'bottom' ) {
	border-#{$border-side}: $width solid theme-color(divider);
}

// Default divider classes
$default-divider-sides: top right bottom left;
@each $side in $default-divider-sides {
	.material-divider-#{$side} {
		@include material-divider(1px, $side);
	}
}
