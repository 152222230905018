@import '../MaterialDesign';
@import './Divider.scss';
@import '../../Helpers.scss';

@import '../../../constants/MaterialDataTableConstants.json';

// Configuration constants
$row-padding: map-get($material-data-table, row-padding);
$column-header-height: #{map-get($material-data-table, column-header-height)}px;
$footer-height: #{map-get($material-data-table, footer-height)}px;
$header-height: #{map-get($material-data-table, header-height)}px;

.material-data-table {
	.header {
		height: auto;
		max-height: none;
		color: theme-color(primary-text);
		padding: 20px 24px 0;

		@include mobile-small() {
			@include fixed-height($header-height);
			padding-left: 16px;
			padding-right: 16px;
			@include material-divider(1px, bottom);
		}

		display: flex;
		align-items: center;
	}

	table, .table {
		border-spacing: 0;
		width: 100%;
		table-layout: fixed;
	}

	thead, .table-header {
		tr.loading-bar-row, .table-row.loading-bar-row {
			@include fixed-height(2px);
			font-size: 0;
			line-height: 0;
			border: 0;
			color: material-color( primary, base );

			td, .table-data {
				height: inherit;
				padding: 0;
				transition: border-color ease-in-out 0.42s;
			}

			&.loading {
				td, .table-data {
					border-color: transparent;
				}
			}
		}

		tr, .table-row {
			@include fixed-height($column-header-height);
		}

		@include material-font(caption);
		color: theme-color(secondary-text);
		text-align: left;

		.sort-asc, .sort-desc {
			color: theme-color(primary-text);
		}
	}

	tr, .table-row {
		box-sizing: border-box;

		td:first-of-type,th:first-of-type,.table-head:first-of-type,.table-data:first-of-type {
			padding-left: 24px;
			@include mobile-small() {
				padding-left: 16px;
			}
		}

		td:last-of-type,th:last-of-type,.table-head:last-of-type,.table-data:last-of-type {
			padding-right: 24px;
			@include mobile-small() {
				padding-right: 16px;
			}
		}

		td.tools-column,th.tools-column,.table-data.tools-column,.table-head.tools-column {
			padding-right: 8px;
			.tool-buttons-container {
				display: flex;
				justify-content: flex-end;
			}
		}

		th,.table-head,td,.table-data {
			padding: 0 #{$row-padding}px 0 #{$row-padding}px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		td,.table-data {
			@include material-divider(1px, bottom);
		}
	}

	tbody, .table-body {
		@include material-font(body1);
		color: theme-color(primary-text);

		tr, .table-row {
			&:hover {
				background-color: theme-color(background);
			}

			&.active, &.active:hover {
				background-color: material-color(primary, selection);
				color: material-foreground-color(primary, selection);
			}
		}
	}

	.footer {
		padding-left: 24px;
		@include fixed-height($footer-height);
	}
}
