@use "sass:math";

@import '../../material-design/MaterialDesign';
@import './ViewerColors.scss';

$toolbar-height: 2em;
$toolbar-spacing: math.div(1em * 13, 24);

div.viewer-toolbar {
	display: flex;
	height: $toolbar-height;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: row;
	color: inherit;
	fill: currentColor;
	background-color: $viewer-toolbar-background-color;
	transform: translateZ(0);

	> * {
		margin-right: $toolbar-spacing;
		&:first-child {
			margin-left: $toolbar-spacing;
		}
	}
}

.m21 .viewer-toolbar > * {
	@include up-to-mobile-extra-small() {
		margin-left: 0;
		margin-right: 0;
	}
}
