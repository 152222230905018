@import '../../material-design/MaterialDesign';

.preview-tile-caption {
	height: 42px;
	max-height: 42px;

	padding: 0 6px 0 6px;

	background-color: rgba( material-color(primary, darker), 0.7);
	color: material-foreground-color(primary, darker);

	overflow: hidden;

	.preview-tile-caption--text-color {
		color: material-foreground-color(primary, darker);
		* {
			color: material-foreground-color(primary, darker);
		}
	}
}

.preview-tile-caption--description {
	color: material-foreground-color(primary, darker);
	text-shadow: 0 0 2px black;
}
