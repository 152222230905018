@import '../../../material-design/MaterialDesign';
@import '../../../Helpers.scss';

.page-footer {
	z-index:1000;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	height: 50px;
	min-height: 50px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;

	background-color: darken( theme-color( background ), 5% );
	color: theme-color(secondary-text);
	fill: currentColor;
	font-family: $font-family;
	font-weight: material-font-weight(subhead);
	font-size: 13px;

	.synedra-logo {
		@include fixed-height(30px);
		padding-left: 10px;
		padding-right: 10px;
	}
}