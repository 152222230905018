.text-measure--container {
	width: 0;
	max-width: 0;
	height: 0;
	max-height: 0;
	overflow: hidden;
	opacity: 0.0;
	pointer-events: none;
	span {
		display: inline;
		white-space: nowrap;
		word-wrap: normal;
		word-break: keep-all;
		overflow-wrap: normal;
		pointer-events: none;
	}
}