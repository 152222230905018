@import '../../material-design/MaterialDesign';
@import '../../material-design/components/Divider';

.patient-details {
	&--container {
		background-color: theme-color(paper);
		padding-top: 0;
	}

	&--scroll-container {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transform: translateZ(0);
	}

	&--width-probe {
		min-height: 0;
		height: 0;
		div {
			min-height: 0;
			height: 0;
			flex-grow: 1;
		}
	}

	&--nrstudies {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		min-height: 48px;

		background-color: theme-color(background);
		white-space: nowrap;
	}

	&--patientfile {
		min-height: 70px;
		padding-right: 24px;

		background-color: theme-color(background);
		white-space: nowrap;
		@include material-divider( 1px, 'bottom' );
	}

	#help-button-button {
		padding: 3px;
	}
}

.print-mode .patient-details {
	display: none;
}
