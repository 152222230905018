@import '../GridSystem';

@each $type, $definition in $material-grid-definitions {
	$total-number-columns: map-get($definition, columns);

	@media screen and #{map-get($definition, screen-size)} {
		.material-column {
			@include material-column-base(map-get($definition, margin));
		}
		@for $column-width from 1 through map-get($definition, columns) {
			.material-column-#{$type}-#{$column-width} {
				@include material-column($total-number-columns, $column-width);
			}
		}
	}
}

.material-column {
	&--justifyCenter {
		justify-content: center;
	}

	&--justifyRight {
		justify-content: flex-end;
	}
}
