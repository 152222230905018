@import '../../../../material-design/MaterialDesign';

.download-body--card {
	display: flex;
	align-items: center;
	max-width: 70%;

	flex-grow: 0;
	padding: 16px;

	.download-info {
		flex-grow: 1;
		padding-left: 16px;

		.download-link {
			text-decoration: underline;
			color: material-color( primary, base );
			white-space: pre-wrap;
			& > *:active {
				color: material-color( accent, base );
			}
		}

		.download-iframe {
			width: 0;
			height: 0;
			border: none;
			visibility: hidden;
		}
	}
}