$hang-tile-hover-opacity: 1.0;
$hang-tile-base-opacity: 0.7;
$after-opacity: 0.6;
$hang-tile-border: 1px;

.hang-tile {
	position: relative;
	flex-grow: 1;
	color: inherit;
	opacity: $hang-tile-base-opacity;
	background: none;
	transition: opacity 0.1s ease-in-out;

	box-sizing: border-box;
	border: $hang-tile-border solid currentColor;

	&.active:after, &:hover:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: currentColor;
		opacity: $after-opacity;
	}

	&:hover {
		opacity: $hang-tile-hover-opacity;
	}
}
