@import '../MaterialDesign';

section.material-dialog-body {
	overflow-y: auto;
	overflow-x: hidden;

	box-sizing: border-box;

	padding: 24px;

	@include mobile-small() {
		padding: 16px;
	}
}
