@import '../Colors';

.material-stepper--progress {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 8px;

	.dot {
		fill: black;
		opacity: 0.38;

		&.active {
			fill: material-color(primary);
			opacity: 1.0;
		}
	}
}
