@import '../MaterialDesign';

$picker-transition-duration: 120ms;
@function from-picker-transition($axis, $step, $ease) {
	@return transform $ease $picker-transition-duration, max-#{$axis} step-#{$step} $picker-transition-duration, opacity $ease $picker-transition-duration, padding $ease $picker-transition-duration;
}

.material-date-range-picker--pickers {
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	align-items: stretch;

	@include mobile-small-portrait() {
		flex-direction: column;
	}
}

.material-date-range-picker {
	display: flex;
	padding: 8px;
	overflow: hidden;
	@include material-font(caption);

	.from-picker {
		@include mobile-small-portrait() {
			padding-bottom: 8px;
		}
		transform-origin: 0 0;
	}

	&.mode-date .from-picker {
		max-width: 0;
		transform: translate(-100%, 0);
		transition: from-picker-transition(width, end, ease-in);

		@include mobile-small-portrait() {
			transition: from-picker-transition(height, end, ease-in);
			max-width: 100%;
			max-height: 0;
			transform: translate(0, -100%);
			padding-bottom: 0;
		}

		opacity: 0.0;
		pointer-events: none;
	}

	&.mode-range .from-picker {
		max-width: 100%;
		max-height: 100%;
		transform: translate(0, 0);
		transition: from-picker-transition(width, start, ease-out);

		@include mobile-small() {
			max-width: 100%;
			max-height:100%;
		}

		@include mobile-small-portrait() {
			transition: from-picker-transition(height, start, ease-out);
		}

		opacity: 1.0;
	}

}

.material-date-range-picker--side-bar {
	padding: 0 16px;
	line-height: 16px;
	@include mobile-small() {
		padding: 0 8px;
	}
}

.material-date-range-picker--predefined-range {
	display: inline-block;
	@include material-font(body2);
	color: material-color(primary);
	cursor: pointer;
	line-height: 1.5em;
	@include mobile-small() {
		line-height: 2em;
	}

	&:hover {
		text-decoration: underline;
	}
}
