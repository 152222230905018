@mixin fixed-size-limits( $size, $axis ) {
	min-#{$axis}: $size;
	max-#{$axis}: $size;
}

@mixin fixed-size( $size, $axis ) {
	@include fixed-size-limits($size, $axis);
	#{$axis}: $size;
}

@mixin fixed-height($height) {
	@include fixed-size( $height, height );
}

@mixin fixed-width($width) {
	@include fixed-size( $width, width );
}

@mixin fixed-width-limits($width) {
	@include fixed-size-limits($width, width);
}

@mixin fixed-height-limits($width) {
	@include fixed-size-limits($width, height);
}

@mixin disable-tap-highlighting() {
	-webkit-tap-highlight-color: transparent;
}
