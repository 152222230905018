@import '../../Helpers.scss';
@import '../../material-design/DeviceTypes';

@mixin sideBySideMode() {
  > * {
    min-width: 0;
    max-width: None;
  }

  > :nth-child(1) {
    flex-grow: 0;
    flex-shrink: 0;
  }

  > :nth-child(2) {
    transform: translateX(100%);
    flex-grow: 1;
    opacity: 0.0;
  }

  &.show-results {
    > * {
      transform: translateX(0%);
      opacity: 1.0;
    }
  }
}

.search-layout {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  > * {
    position: relative;
    transition: transform 180ms ease-in, opacity 180ms ease-in;

    transform: translateX(0%);
    @include fixed-width(100%);
    border: none;
    box-shadow: none;
    opacity: 1.0;
  }
  &.show-results {
    > * {
      transform: translateX(-100%);
    }
  }

  &.side-by-side {
    @include sideBySideMode();
  }

  &.auto-side-by-side {
    @include greater-than-extra-large {
      @include sideBySideMode();
    }
  }
}
