@import '../../material-design/MaterialDesign';
@import '../../material-design/GridSystem';
@import '../../Helpers';

.search-layout {
	.search-form-container {
		overflow-x: hidden;
		overflow-y: auto;

		@include greater-than-extra-large {
			.search-body--search-form-wrapper {
				padding-right: 12px;
			}
		}
	}

	@include mobile-small() {
		.material-card-header {
			display: none;
		}
	}

	.search-form-container {
		@include greater-than-extra-large {
			@include fixed-width(350px);
		}
	}

	.data-table-container {
		display: flex;

		@include greater-than-extra-large {
			.data-table-wrapper {
				padding-left: 12px;
			}
		}

		.data-table {
			.header {
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
			}

			@include mobile-small() {
				tbody td span.patient-info {
					@include material-font(body2);
				}
			}

			@include greater-than-extra-large {
				align-self: stretch;
				.search-parameters {
					display: none;
				}
			}
		}
	}
}
