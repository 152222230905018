@import '../../material-design/MaterialDesign';
@import '../../Helpers';

@import '../../../constants/MaterialDataTableConstants.json';

$row-min-height: map-get($material-data-table, row-min-height);
$row-line-height: map-get($material-data-table, row-line-height);
$row-padding: map-get($material-data-table, row-padding);

@function row-height( $rows ) {
	@return max($row-min-height, $rows * $row-line-height + 2*$row-padding);
}

// Derived constants
$desktop-row-height: #{row-height(1)}px;
$mobile-row-height: #{row-height(2)}px;
$small-mobile-row-height: #{row-height(3)}px;


.document-search-table-row {
	@include desktop-size() {
		@include fixed-height($desktop-row-height);
	}

	@include mobile() {
		@include fixed-height($mobile-row-height);
	}

	@include mobile-small() {
		@include fixed-height($small-mobile-row-height);
	}
}
