@import '../../material-design/MaterialDesign';

.webview-details-app-bar {
	&--user-info {
		flex-wrap: nowrap;
		justify-content: flex-end;
		@include mobile-small() {
			display: none;
		}
	}

	&--patient-info {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		.patient-name {
			@include material-font(title);
			& > span {
				font: inherit;
			}
		}
	}
}
