@import '../../MaterialDesign';

.material-primary-tile-text {
	@include material-font(body2);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;

	* {
		@include material-font(body2);
	}
}