@import '../../material-design/MaterialDesign';
@import '../../material-design/GridSystem';

.document-share {
	display: flex;
	align-items: center;
	align-content: center;
	line-height:1.5em;
	min-height:48px;

	.document-share--document-information {
		display: inline-block;
		max-width: 100%;
		vertical-align: middle;
		white-space: nowrap;
		min-width: 0;
		flex-grow: 1;
		text-overflow: ellipsis;
		overflow: hidden;
		@include material-font(body2);
	}



	.document-share--share-information {
		@include material-font(caption);
		text-align:right;
		display: flex;
		align-items: center;
		max-width: 100%;
		flex-wrap:nowrap;
		white-space: normal;

		.document-share--share-date-information {
			white-space:nowrap;
		}
	}

	.document-share--document-information {
	}

	.document-share--share-information {
		color: theme-color(secondary-text);
	}

	.document-share--valid-until {
		min-width: 0;
		flex-shrink:1;
	}

	.document-share--buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 1em;
		color: theme-color(secondary-text);
	}
}

@media screen and #{screen-size-between-rule(extra-small, medium)} {
	.document-share {
		flex-wrap: wrap;

		.document-share--document-information {
			width: 100%;
		}

		.document-share--share-information {
			text-align:left;
			flex-grow: 1;

		}

		.document-share-buttons {
			flex-grow: 1;
		}

		.document-share--valid-until {
			flex-grow: 1;
			width: 100%;
		}
	}
}

.document-share--remaining-days, .document-share--share-date {
	white-space: nowrap;
}


