@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';

.inbox-scroll--area {
	flex-grow: 1;
}

.inbox-result-list--container {
	.inbox-result-list--content {
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		flex-wrap: nowrap;
	}
}

//collapse borders on small screens
@media screen and #{screen-size-between-rule(extra-small, medium)} {
	.inbox-result-list--container, .inbox-result-list--container .inbox-result-list--content {
		padding: 0;
		margin:0;
	}
}
