@import '../MaterialDesign';

.material-toolbar {
	@include material-background(primary);
	height: 48px;
	min-height: 48px;
	transform: translateZ(0);

	display: flex;
	align-items: center;

	box-shadow: none;
	box-sizing: border-box;

	overflow: hidden;

	@include material-elevation(1, 'bottom', 1);
	@include mobile() {
		border-bottom: none;
		@include material-elevation(4, 'bottom');
	}
	@include mobile-portrait() {
		height: $standard-mobile-height-increment;
		min-height: $standard-mobile-height-increment;
	}
	@include desktop-size() {
		height: $standard-desktop-height-increment;
		min-height: $standard-desktop-height-increment;
		max-height: $standard-desktop-height-increment;
	}

	&-row {
		flex-grow: 1;
	}
}

$toolbar--content-padding-small: 16px;
$toolbar--content-padding-large: 24px;
