@import '../Colors';

.app-bar-search-filter {
	margin: 0 16px;
	flex-grow: 1;
	color: material-foreground-color(primary, base);
	fill: material-foreground-color(primary, base);
	border: none;
	background: rgba(#ffffff, 0.2);

	&:hover {
		background: rgba(#ffffff, 0.3);
		fill: material-foreground-color(primary, base);
		border: none;
	}
	&.active {
		background: theme-color(paper);
		fill: theme-color(primary-text);
		color: theme-color(primary-text);
		border: none;
		margin: 0 16px 0 0;
	}

	.search-filter--input {
		&:hover {
			&::placeholder {
				color: rgba(#ffffff, 0.8);
			}
		}

		&::placeholder {
			color: rgba(#ffffff, 0.7);
		}
	}
}