.annotation-element, .modifiable-path--line, .annotation-element--contour, .modifiable-path--contour-line {
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.annotation-element--contour, .modifiable-path--contour-line {
  stroke: black;
  stroke-width: 3;
}

.hover .annotation,
.hover .annotation-label,
.hover .annotation-element,
.hover .modifiable-path--line,
.hover .annotation-element--contour,
.hover .modifiable-path--contour-line,
.hover .marker-dot,
.hover .annotation-icon--icon, .annotation-active .annotation,
.annotation-active .annotation-label,
.annotation-active .annotation-element,
.annotation-active .modifiable-path--line,
.annotation-active .annotation-element--contour,
.annotation-active .modifiable-path--contour-line,
.annotation-active .marker-dot,
.annotation-active .annotation-icon--icon {
  color: #80d4d9;
}
@media screen and (max-width: 960px ) {
  .hover .annotation-visible-touch-area, .annotation-active .annotation-visible-touch-area {
    opacity: 0.25;
  }
}

.annotation-visible-touch-area {
  transition: opacity 72ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
@media print {
  .annotation-visible-touch-area {
    display: none;
  }
}