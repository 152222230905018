@import '../../../material-design/Variables';

.hover, .annotation-active {
	.annotation-icon--icon {
		opacity: 1;
		pointer-events: auto;
	}
}

.annotation-icon--icon {
	pointer-events: none;
	transition: transform 72ms $swift-ease-out-timing-function, opacity 72ms $swift-ease-out-timing-function;
	opacity: 0;
	fill: currentColor;
	stroke: none;

	@media print {
		display: none;
	}
}