.webgl-image-viewer--canvas {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	transform: translateZ(0);
}

.webgl-image-viewer--inner {
	position: absolute;
	top: 0px;
	left: 0px;
}
