@import '../MaterialDesign';

.material-card {
	@include material-elevation(2);

	display: inline-block;
	flex-grow: 1;

	background-color: theme-color(paper);
	border-radius: 4px;
	max-width: 100%;
	min-width: 0;
}

.material-card--picked-up {
	@include material-elevation(8);
}
