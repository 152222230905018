@import '../../../material-design/MaterialDesign';

.warning-caption {
	display: flex;
	align-items: center;
	color: material-color( accent, base );

	@include material-font(title);
	svg {
		fill: currentColor;
	}
}