.link {
	text-decoration: none;
	color: inherit;
	fill: inherit;

	&:active {
		text-decoration: none;
		color: inherit;
		fill: inherit;
	}
}