.step-additional-data {
	&--form {
		margin-bottom: 30px;
		display: block;
		flex-shrink: 0;
		flex-grow: 0;
	}

	&--total-upload-data-size {
		padding-top: 16px;
		padding-bottom: 16px;
		text-align: right;
	}

	.material-grid-list {
		flex-shrink: 0;
		overflow: hidden;
	}
}
