.webview-page--sidebar--menu-and-branding {
	height: 0;
	min-height: 0;
	flex-shrink: 1;
	flex-grow: 1;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.webview-page--body-container {
	max-width: 100vw;
	display: flex;
	flex-grow: 1;
	overflow: hidden;
}