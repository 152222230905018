@import '../../constants/MaterialDeviceSizes.json';

$material-breakpoints: (
	extra-small: (
		max-width: map-get($device-size-extra-small, max-width) + 0px
	),
	small: (
		min-width: map-get($device-size-small, min-width) + 0px,
		max-width: map-get($device-size-small, max-width) + 0px,
	),
	medium: (
		min-width: map-get($device-size-medium, min-width) + 0px,
		max-width: map-get($device-size-medium, max-width) + 0px,
	),
	large: (
		min-width: map-get($device-size-large, min-width) + 0px,
		max-width: map-get($device-size-large, max-width) + 0px,
	),
	extra-large: (
		min-width: map-get($device-size-extra-large, min-width) + 0px,
		max-width: map-get($device-size-extra-large, max-width) + 0px,
	),
	extra-extra-large: (
		min-width: map-get($device-size-extra-extra-large, min-width) + 0px
	)
);

@function screen-size-between-rule($breakpointLow, $breakpointUpper) {
	$low-size-information: map-get($material-breakpoints, $breakpointLow);
	$upper-size-information: map-get($material-breakpoints, $breakpointUpper);

	@if map-has-key($low-size-information, min-width) and map_has_key($upper-size-information, max-width) {
		@return '(min-width: ' map-get($low-size-information, min-width) ') and (max-width: ' map-get($upper-size-information, max-width) ')';
	}
	@else if map-has-key($low-size-information, min-width) {
		@return '(min-width: '  map-get($low-size-information, min-width) ')';
	}
	@else if map_has_key($upper-size-information, max-width) {
		@return '(max-width: ' map-get($upper-size-information, max-width) ')';
	}
}

@mixin screen-size-between($breakpointLow, $breakpointUpper: 'extra-extra-large', $additional-rule: false) {
	$size-condition: screen-size-between-rule($breakpointLow, $breakpointUpper);

	@if($additional-rule == false) {
		@media screen and #{$size-condition} {
			@content;
		}
	}
	@else {
		@media screen and #{$size-condition} and #{$additional-rule} {
			@content;
		}
	}
}

@function screen-size-smaller-rule($breakpointUpper) {
	$upper-size-information: map-get($material-breakpoints, $breakpointUpper);
	@if map_has_key($upper-size-information, max-width) {
		@return '(max-width: ' map-get($upper-size-information, max-width) ')';
	}
}

@function screen-size-greater-rule($breakpointLower) {
	$upper-size-information: map-get($material-breakpoints, $breakpointLower);
	@if map_has_key($upper-size-information, max-width) {
		@return '(min-width: ' map-get($upper-size-information, max-width) + 1px')';
	}
	@else {
		@return false;
	}
}

@mixin screen-size-smaller-or-equal($breakpoint, $additional-rule: false ) {
	$size-condition: screen-size-smaller-rule($breakpoint);
	@if($additional-rule == false) {
		@media screen and #{$size-condition} {
			@content;
		}
	}
	@else {
		@media screen and #{$size-condition} and #{$additional-rule} {
			@content;
		}
	}
}

@mixin screen-wider-than($breakpoint, $additional-rule: false) {
	$size-condition: screen-size-greater-rule($breakpoint);
	@if($additional-rule != false and $size-condition != false) {
		@media screen and #{$size-condition} and #{$additional-rule} {
			@content;
		}
	}
	@else if($additional-rule != false) {
		@media screen and #{$additional-rule} {
			@content;
		}
	}
	@else if($size-condition != false) {
		@media screen and #{$size-condition} {
			@content;
		}
	}
}

@mixin screen-size($breakpoint, $additional-rule: true) {
	@include screen-size-between($breakpoint, $breakpoint);
}

@mixin mobile-small {
	@include screen-size-between(extra-small, small ) {
		@content;
	}
}

@mixin up-to-mobile-small {
	@include screen-size-smaller-or-equal(small) {
		@content;
	}
}

@mixin up-to-mobile-extra-small {
	@include screen-size-smaller-or-equal(extra-small) {
		@content;
	}
}

@mixin greater-than-mobile-small {
	@include screen-wider-than(small) {
		@content;
	}
}

@mixin mobile-small-portrait {
	@include screen-size-between(extra-small, small, '(max-aspect-ratio: 1/1)') {
		@content;
	}
}

@mixin up-to-mobile-small-portrait {
	@include screen-size-smaller-or-equal(small, '(max-aspect-ratio: 1/1)') {
		@content;
	}
}

@mixin greater-than-mobile-small-portrait {
	@include screen-wider-than(small, '(max-aspect-ratio: 1/1)'){
		@content;
	}
}

@mixin mobile {
	@include screen-size-between(extra-small, medium) {
		@content;
	}
}

@mixin up-to-mobile {
	@include screen-size-smaller-or-equal(medium) {
		@content;
	}
}

@mixin greater-than-mobile {
	@include screen-wider-than(medium) {
		@content;
	}
}

@mixin mobile-portrait {
	@include screen-size-between(extra-small, medium, '(max-aspect-ratio: 1/1)') {
		@content;
	}
}

@mixin up-to-mobile-portrait {
	@include screen-size-smaller-or-equal(medium, '(max-aspect-ratio: 1/1)') {
		@content;
	}
}

@mixin greater-than-mobile-portrait {
	@include screen-wider-than(medium, '(max-aspect-ratio: 1/1)') {
		@content;
	}
}

@mixin desktop-size {
	@include screen-size-between(large) {
		@content;
	}
}

@mixin up-to-desktop-size {
	@include screen-size-smaller-or-equal(large) {
		@content;
	}
}

@mixin greater-than-desktop-size {
	@include screen-wider-than(large) {
		@content;
	}
}

@mixin up-to-wide-size {
	@include screen-size-smaller-or-equal(wide) {
		@content;
	}
}

@mixin greater-than-extra-large {
	@include screen-wider-than(extra-large) {
		@content;
	}
}
