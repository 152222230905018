.documents-browser-images-grid-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	flex-grow: 0;

	overflow-y: auto;
	overflow-x: hidden;

	padding-top: 30px;
	padding-bottom: 30px;

	.material-grid-tile {
		position: relative;
		overflow: hidden;
		transform: translateZ(0);
		background-color: black;
	}

	.photo-upload-button-tile {
		background: inherit;
		.photo-upload-button {
			height: 100%;
			padding: 0;
		}
	}
}
