.material-loading-bar {
	width: 100%;
	height: inherit;
	color: inherit;
	background: transparent;

	opacity: 0;
	animation-play-state: paused;
	transition: opacity ease-in-out 0.42s;

	&.loading {
		opacity: 1;
		animation-play-state: running;
	}
}

.material-loading-bar rect{
	fill: currentColor;
	fill-opacity: 0.18;
}

.material-loading-bar line{
	stroke: currentColor;
	stroke-width: 1;
	stroke-dasharray: 75 75 75 200;
	animation: dash 3s ease-in-out infinite;
	animation-play-state: inherit;
}

@keyframes dash {
	0% {
		stroke-dasharray: 138 75 75 100;
		stroke-dashoffset: 288;
	}

	100% {
		stroke-dashoffset: -100;
		stroke-dasharray: 10 75 75 100;
	}
}