@import '../MaterialDesign';
@import '../../../constants/SidebarConstants.json';
@import '../../Helpers.scss';

$mobile-sidebar-width: 5*$standard-mobile-height-increment;
$desktop-sidebar-width: 5*$standard-desktop-height-increment;

$desktop-animation-time: #{map-get($sidebar-animation-times, desktop )}ms;
$mobile-animation-time: #{map-get($sidebar-animation-times, mobile )}ms;

.sidebar {
	display: flex;
	width: $desktop-sidebar-width;
	min-width: $desktop-sidebar-width;
	transform: translateZ(0);

	max-height: 100%;
	overflow: hidden;

	@include disable-tap-highlighting();

	.sidebar--content {
		cursor: default;
		position: relative;
		background-color: theme-color(paper);
		overflow: hidden;
		margin-left: 2px;

		left: 100%;
		min-width: $desktop-sidebar-width;
		max-height: 100vh;
		@include material-elevation(1, 'left');

		transform: translate(-100%,0);

		&.transitions {
			transition:
				transform $desktop-animation-time ease-out,
				width $desktop-animation-time step-start,
				min-width $desktop-animation-time step-start;
		}
	}

	&.is-hidden {
		width: 0;
		min-width: 0;
		z-index: 2;
		overflow: visible;

		.sidebar--content {
			width: 0;
			min-width: 0;
			transform: translate(-100%,-100vh);

			&.transitions {
				transition:
					transform $desktop-animation-time ease-in,
					width $desktop-animation-time step-end,
					min-width $desktop-animation-time step-end;
			}
		}
	}

	&.is-elevated {
		overflow: visible;
		width: $mobile-sidebar-width;
		min-width: $mobile-sidebar-width;
		.sidebar--content {
			min-width: $mobile-sidebar-width;
		}
	}

	&.is-elevated {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 10000;
		cursor: pointer;

		&.transitions {
			transition:
				width $mobile-animation-time step-start,
				min-width $mobile-animation-time step-start,
				opacity $mobile-animation-time ease-out;
		}

		&::before {
			background-color: black;
			content: '';
			position: absolute;
			width: 100vw;
			height: 100vh;
			opacity: 0.3;
			transition: inherit;
		}

		.sidebar--content {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			position: absolute;
			height: 100vh;
			max-width: 60%;
			left: 0;
			min-width: $mobile-sidebar-width;
			@include material-elevation(16, 'right');
			margin-left: 0;

			z-index: 10000;
			transform: translate(0,0);
			&.transitions {
				transition:
					box-shadow .0s linear,
					transform $mobile-animation-time ease-out,
					width $mobile-animation-time step-start,
					min-width $mobile-animation-time step-start;
			}
		}

		&.is-hidden {
			width:0;
			min-width: 0;
			z-index: 10000;

			&.transitions {
				transition:
					width $mobile-animation-time step-end,
					min-width $mobile-animation-time step-end,
					opacity $mobile-animation-time ease-in;
			}

			&::before {
				width: 0;
				min-width: 0;
				opacity: 0;
				transition: inherit;
			}

			.sidebar--content {
				position: absolute;
				width: 0;
				min-width: 0;

				box-shadow: none;

				&.transitions {
					transition:
						box-shadow $mobile-animation-time step-end,
						transform $mobile-animation-time ease-in,
						width $mobile-animation-time step-end,
						min-width $mobile-animation-time step-end;
				}

				transform: translate(-100%, 0);
			}
		}
	}
}
