@import '../../material-design/Colors';

.embedded-pdf-viewer--container {
	position:absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	text-align: center;
}

@media print {
	.print-message {
		background-color: white;
		color: black;
	}
}