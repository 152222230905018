@import '../../viewer/components/ViewerColors';

.previewable-image {
	fill: $viewer-foreground-color;

	> * {
		opacity: 0.3;
	}

	> img {
		opacity: 1.0;
	}
}