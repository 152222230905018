@import '../../material-design/MaterialDesign';
@import '../../material-design/GridSystem';
@import '../../Helpers';

@mixin full-width-column( $grid-definition ) {
	@include material-column(map-get($grid-definition, columns), map-get($grid-definition, columns) );
}

$wide-screen-height-breakpoint: 890px;

.document-search-body {
	&--search-form {
		@include screen-size-between(extra-small, extra-small) {
			$grid-definitions: map-get($material-grid-definitions, xs);

			.material-column-base {
				@include material-column-base(map-get($grid-definitions, margin));
			}

			.patient-id-field, .last-name-field, .first-name-field, .description-field, .document-created-when-field, .birth-date-field, .sex-field, .modality-field {
				@extend .material-column-base;
				@include full-width-column($grid-definitions);
			}
		}

		@include screen-size-between(small, extra-extra-large) {
			$grid-definition: map-get($material-grid-definitions, sm);

			.modality-field, .birth-date-field, .sex-field, .patient-id-field, .last-name-field, .first-name-field {
				@include material-column-base(map-get($grid-definition, margin));
				@include material-column(map-get($grid-definition, columns), 2 );
			}
			.document-created-when-field, .description-field {
				@include material-column-base(map-get($grid-definition, margin));
				@include material-column(map-get($grid-definition, columns), 3 );
			}
		}

		@include greater-than-extra-large {

			$grid-definitions: map-get($material-grid-definitions, lg);

			@media screen and (min-height: $wide-screen-height-breakpoint + 1px) {
				.patient-id-field, .last-name-field, .first-name-field, .birth-date-field, .sex-field, .document-created-when-field, .modality-field, .description-field {
					@include material-column-base(map-get($grid-definitions, margin));
					@include full-width-column($grid-definitions);
				}
			}
			@media screen and (max-height: $wide-screen-height-breakpoint) {
				.patient-id-field, .last-name-field, .first-name-field, .description-field, .document-created-when-field {
					@include material-column-base(map-get($grid-definitions, margin));
					@include full-width-column($grid-definitions);
				}

				.birth-date-field, .sex-field, .modality-field {
					@include material-column-base(map-get($grid-definitions, margin));
					@include material-column(map-get($grid-definitions, columns), 6);
				}
			}
		}
	}
}
