@import '../../material-design/MaterialDesign';

.tool-buttons-container {
	color: theme-color(secondary-text);
}

.document-search-datatables-medium-header--first-row {
	display: flex;
	width: 100%;
	align-items: center;

	.document-search-datatables-medium-header--sort-combo {
		min-width: 310px;
	}

	.document-search-datatables-medium-header--title {
		flex-grow: 1;
		width: 100%;
	}
}

.document-search-body .document-search-body--data-table .small.header {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
}
