@import '../../material-design/Colors';

.pdf-page--container {
	position: relative;
	overflow: hidden;
	color: theme-color(disabled-text);
	text-align: center;

	> * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.hidden {
		display: none;
	}
}

.pdf-page--textlayer {
	position: absolute;
	overflow: hidden;
	opacity: 0.2;
	line-height: 1.0;
}

.pdf-page--textlayer > span {
	color: transparent;
	position: absolute;
	white-space: pre;
	cursor: text;
	transform-origin: 0 0;
}

.pdf-page--textlayer ::-moz-selection { background: rgb(0,0,255); }

.pdf-page--textlayer ::selection { background: rgb(0,0,255); }

