@import '../../../material-design/MaterialDesign';

$icon-opacity: 0.75;

.sync-point-annotation-number-icon {
	opacity: $icon-opacity;
	@media print {
		display: inline;
	}
	text {
		color: material-foreground-color();
		fill: currentColor;
		text-anchor: middle;

		@media print {
			color: black
		}
	}
}