@import '../../material-design/MaterialDesign';

$border-margin: 10px;

@mixin corner( $vertical-alignment, $horizontal-alignment ) {
	.#{$vertical-alignment}-#{$horizontal-alignment} {


		@include mobile-small() {
			max-width: 33%;
			max-height: 33%;
		}

		@if ($horizontal-alignment == right) {
			text-anchor: end;
		} @else if ($horizontal-alignment == center) {
			text-anchor: middle;
		}

		&>span {
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		@content
	}
}

.meta-data-overlay {
	transform: translateZ(0);
	position: absolute;

	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	flex-grow: 1;
	pointer-events: none;

	background: transparent;
	overflow: hidden;
	filter: drop-shadow(1px 1px 0px black);
	fill: currentColor;

	@include corner(top, left);
	@include corner(top, right);
	@include corner(bottom, left);
	@include corner(bottom, right);

	@include corner(top, center);
	@include corner(bottom, center);
	@include corner(center, left);
	@include corner(center, right);

	.contour {
		transform: translate(1px, 1px);
		fill: black;
	}
}
