@import '../../viewer/components/ViewerColors.scss';
@import '../../material-design/MaterialDesign';

// TODO: Extracted from patient details preview images styles. A generic material image list component should be created, which gets used by all components in need for image lists.

.archive-image {
  	&.selectable {
		cursor: pointer;
	}

	&--overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: $viewer-foreground-color;

		background: transparent;

		&.selected {
			background: rgba($viewer-foreground-color, 0.6);
		}

		&-caption {
			height: 42px;
			max-height: 42px;
			box-sizing: border-box;
			max-width: 100%;

			padding: 0 6px 0 6px;

			background-color: rgba( material-color(primary, darker), 0.7);
			color: material-foreground-color(primary, darker);

			overflow: hidden;
		}

		&-select-icon {
			width: 24px;
			height: 24px;
			padding: 5px;
			color: material-color(primary, base);

			svg {
				border-radius: 50%;
				fill: white;
				@include material-elevation(2, bottom, $cssFilter: true);
			}
		}
	}
}

