@import '../../material-design/MaterialDesign';

.search-appbar--loading-bar {
	position: absolute;
	top: 100%;
	left: 0;
	color: material-color(accent, base);
	transform: translateY(-100%);
	height: 3px;
}
