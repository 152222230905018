@import '../MaterialDesign';

.search-filter--container {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	height: 36px;
	flex-grow: 0;
	padding: 5px;
	border-radius: 3px;
	border: 1px solid theme-color(secondary-text);
	fill: theme-color(secondary-text);
	transition: background 0.1s, color 0.1s, fill 0.1s, border 0.1s;
	cursor: text;

	&:hover {
		fill: theme-color(primary-text);
		border: 1px solid theme-color(primary-text);
	}
	&.active {
		fill: theme-color(primary-text);
		border: 1px solid theme-color(primary-text);
	}
}

.search-filter--icon-base {
	height: 24px;
	width: 24px;
	padding: 0 8px 0 8px;
	cursor: default;

	&.hidden {
		visibility: hidden;
	}

	@include desktop-size() {
		padding: 0 16px 0 16px;
	}
}

.search-filter--input {
	@include material-font(subhead);

	&::-ms-clear {
		display: none;
	}

	flex-grow: 1;
	background: none;
	border: none;
	outline: none;
}
