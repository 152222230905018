@import '../../../material-design/MaterialDesign';
@import '../../../Helpers.scss';

.change-password-dialog--content {
	@include greater-than-mobile-small() {
		@include fixed-width(320px);
	}

	@include mobile-small() {
		.new-password-form {
			justify-content: space-around;
		}
	}
}

