@import '../../material-design/DeviceTypes';

.image-viewer-unsupported--download-items {
	a {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 8px 0 0 0;

		:last-child {
			padding: 0 0 0 8px;
		}
	}
}