.overlay-container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background: rgba(black, 0.3);
	transition: opacity 0.28s;
	opacity: 0;
	pointer-events: none;

	&:not(.visible)>* {
		display: none;
	}

	&.visible {
		opacity: 1;
		pointer-events: initial;
	}
}
