.material-dialogbuttoncontainer {
	display: flex;
	justify-content: flex-end;
	padding: 8px 8px 8px 24px;
	flex-direction: row;
	flex-wrap: wrap;

	& > *:first-child {
		padding-left: 0;
	}
	& > * {
		padding: 0 0 0 8px;
	}

	&.align-vertically {
		flex-direction: column;
		flex-wrap: wrap;
		align-items: stretch;
		padding: 8px 16px 8px 16px;

		& > *:first-child {
			padding-top: 0;
		}
		& > * {
			padding: 8px 0 0 0;
		}
	}
}
