@import './ViewerColors.scss';

$viewer-container-border-size: 2px;

.viewer--container {
	display: flex;
	flex-grow: 1;
	background-color: black;
	color: $viewer-foreground-color;

	box-sizing: border-box;
	border: $viewer-container-border-size solid $inactive-viewer-border-color;

	&.active-border {
		border-color: $active-viewer-border-color;
	}

	&.fullscreen {
		background-color: transparent;
		position: fixed;
		.full-screen-container--content {
			background-color: black;
		}
	}

	@media print {
		border-width: 0;
	}
}
