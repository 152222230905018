.canvas-image-viewer--container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	> * {
		position: absolute;
		left: 0;
		top: 0;
	}
}

.canvas-image-viewer--print-background {
	width: 100%;
	height: 100%;
	fill:black;
}

.canvas-image-viewer--container canvas {
	display: inline-block;
};
