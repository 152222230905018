.intermediate-page {
	width: 100%;
	height: 100%;

	box-sizing: border-box;

	&--content {
		padding-top: 30px;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}