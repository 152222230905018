$shadow-position-configurations: (
	top: (
		hFactor: 0,
		vFactor: -1
	),
	bottom: (
		hFactor: 0,
		vFactor: 1
	),
	left: (
		hFactor: -1,
		vFactor: 0
	),
	right: (
		hFactor: 1,
		vFactor: 0
	)
);

@mixin material-elevation($level, $shadow-position: 'bottom', $z-index: false, $cssFilter: false ) {
	@media screen {
		@if ($z-index == false) {
			$z-index: $level;
		}

		@if ($level > 0) {
			$hFactor: map-get(map-get($shadow-position-configurations, $shadow-position), hFactor);
			$vFactor: map-get(map-get($shadow-position-configurations, $shadow-position), vFactor);

			$level-square: $level * $level;

			$distance: -0.00255066 * $level-square + 0.771226 * $level - 0.013139;
			//$distance: -0.0236234 * ($i*$i) + 1.07599 * $i - 0.0793976;

			$blur: -0.000408998 * $level-square + 2.02487 * $level - 0.345521;
			$blur_v2: -0.00606061 * $level-square + 2.19394 * $level - 1.16364;

			$spread: -0.000378788 * $level-square + 0.137121 * $level - 0.0727273;

			$opacity: 0.000132998 * $level-square - 0.00621939 * $level + 0.389164;

			@if (not $cssFilter) {
				box-shadow: #{$distance*$hFactor}px #{$distance*$vFactor}px #{$blur}px #{$spread}px rgba(0, 0, 0, $opacity);
			} @else {
				filter: drop-shadow(#{$hFactor*($distance + $spread)}px #{$vFactor*($distance + $spread)}px #{$blur}px black);
			}
		} @else {
			box-shadow: none;
		}
	}
	z-index: $z-index;
}