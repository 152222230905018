@import '../../../material-design/MaterialDesign';
@import '../../../Helpers.scss';

.landing-page {
	.material-title {
		padding: 10px;
		flex-shrink: 0;
	}

	.header-iframe {
		display: flex;
		justify-content: center;
		align-self: stretch;
		@include fixed-height(100px);
	}

	& &--central-card {
		text-align: left;
		position: relative;
		padding: 20px;
		min-height: 300px;
		box-sizing: border-box;
		> * {
			width: 100%;
		}
	}

	.footer-iframe {
		display: flex;
		justify-content: center;
		align-self: stretch;
		flex-grow: 1;
		min-height: 100px;
	}
}
