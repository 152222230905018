@import '../Colors';
@import '../DeviceTypes';
@import './Stepper.scss';

@import './Divider.scss';

footer.material-stepper--footer {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	min-height: 48px;
	max-height: 48px;
	@include material-stepper-padding();

	&.only-next-button {
		justify-content: flex-end;
	}

	@include mobile-small() {
		background-color: theme-color(background);
	}

	.progress {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}