@import './ViewerColors.scss';

.viewer-box {
	overflow: hidden;
	width: 100%;
	height: 100%;

	color: $viewer-foreground-color;

	@media print {
		color: white;
	}

	.viewer-box--viewer {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		position: relative;
		overflow: hidden;
	}
}
