@import '../../../material-design/MaterialDesign';

.unhandled-errors-dialog {
	.material-dialog--content {
		max-width: 60%;
		max-height: 60%;

		@include mobile-small() {
			max-width: initial;
			max-height: initial;
		}
	}
}