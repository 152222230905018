@import '../text/PrimaryItemText.scss';

.material-primary-action {
	display: flex;
	flex-grow: 1;
	align-items: center;
	flex-shrink: 1;

	@extend .material-primary-item-text;

	* h6 {
		line-height: normal;
	}
}
