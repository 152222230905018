@import '../../material-design/MaterialDesign';

.search-parameters {
	&--list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		@include material-font(body1);

		&--parameters {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;

			.parameter {
				&:not(:last-child) {
					padding-right: 8px;
				}
				color: theme-color(secondary-text);
				.value {
					@include material-font(body2);
					color: theme-color(primary-text);
					padding-left: 4px;
				}
			}
		}
	}

	&--show-form-button {
		flex-shrink: 0;
		padding: 0;
		min-width: 0;
		min-height: 24px;
		height: 1em;
		margin-left: 8px;

		button {
			width: initial;
			height: 24px;
		}

		svg {
			height: 16px;
		}
	}
}
