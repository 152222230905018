@import '../../material-design/MaterialDesign';
@import '../../Helpers';

@import '../../../constants/MaterialDataTableConstants.json';

@mixin full-width-button-container-style() {
	padding-top: 8px;
	padding-left: 0;
	flex-direction: column-reverse;
	align-items: stretch;
	justify-content: center;

	.form-evaluation-error {
		flex-grow: 0;
		align-self: center;
		order: 3;
	}

	&>*:nth-child(3) {
		order: 2;
	}

	.reset-link {
		padding-left: 8px;
	}
}

.search-form {
	overflow: hidden;

	&--fields-container {
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;

		@include mobile-small() {
			padding: 0;
		}
	}

	&--buttons-container {
		align-items: center;
		flex-wrap: nowrap;
		@include mobile-small-portrait() {
			@include full-width-button-container-style();
		}

		@media screen and (min-width: 1601px) {
			@include full-width-button-container-style();
		}

		.search-button {
			min-width: 150px;
		}

		.reset-link {
			flex-shrink: 0;
		}

		.form-evaluation-error {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;
			flex-shrink: 1;

			white-space: nowrap;

			box-sizing: border-box;
			padding: 4px;
			@include fixed-height(22px);
			@include material-font(button);
		}
	}
}
