@import '../../../constants/FullScreenWrapperConstants.json';

$animation-time: #{$full-screen-container-animation-time}ms;

.full-screen-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: $full-screen-container-z-Index;

	display: flex;
	justify-content: center;
	align-items: center;

	&--content {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $full-screen-container-z-Index + 1;
		visibility: hidden;

		display: flex;
		justify-content: center;
		align-items: center;

		pointer-events: initial;
	}
}
