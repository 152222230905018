@import './AnnotationBaseStyles.scss';
@import '../../../../constants/AnnotationConstants.json';

.sync-point-marker {
	@extend .annotation-element;

	.sync-point-marker--contour {
		@extend .annotation-element--contour;
		stroke-width: $annotation-line-width + 0.5;
	}

	&.not-in-slice-state {
		.sync-point-marker--circle {
			stroke-dasharray: 5,5;
		}
	}

	&.undefined-state {
		opacity: 0.72;
	}
}
