@import './DeviceTypes.scss';

$font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$font-weights: ("light": 300, "regular": 400, "medium": 500, "bold": 700);

$material-fonts: (
	display-4: (
		weight: map-get($font-weights, "light"),
		size: (
			default: 112px
		)
	),
	display-3: (
		weight: map-get($font-weights, "regular"),
		size: (
			default: 56px
		)
	),
	display-2: (
		weight: map-get($font-weights, "regular"),
		size: (
			default: 45px
		)
	),
	display-1: (
		weight: map-get($font-weights, "regular"),
		size: (
			default: 34px
		)
	),
	headline: (
		weight: map-get($font-weights, "regular"),
		size: (
			default: 24px
		)
	),
	title: (
		weight: map-get($font-weights, "medium"),
		size: (
			default: 20px
		)
	),
	subhead: (
		weight: map-get($font-weights, "regular"),
		size: (
			desktop: 15px,
			default: 16px
		)
	),
	subhead2: (
		weight: map-get($font-weights, "bold"),
		size: (
			desktop: 15px,
			default: 16px
		)
	),
	body3: (
		weight: map-get($font-weights, "bold"),
		size: (
			desktop: 13px,
			default: 14px
		)
	),
	body2: (
		weight: map-get($font-weights, "medium"),
		size: (
			desktop: 13px,
			default: 14px
		)
	),
	body1: (
		weight: map-get($font-weights, "regular"),
		size: (
			desktop: 13px,
			default: 14px
		)
	),
	caption: (
		weight: map-get($font-weights, "regular"),
		size: (
			desktop: 12px,
			default: 12px
		)
	),
	button: (
		weight: map-get($font-weights, "medium"),
		size: (
			desktop: 14px,
			default: 14px
		)
	)
);

@function material-font-weight($kind) {
	$fontInformation: map-get($material-fonts, $kind);
	@return map-get($fontInformation, 'weight')
}

@function material-font-size($kind, $deviceType: 'default') {
	$font-information: map-get($material-fonts, $kind);
	$font-size-information: map-get($font-information, size);

	@return map-get($font-size-information, $deviceType);
}

@function material-font-has-device-type($kind, $deviceType) {
	$font-information: map-get($material-fonts, $kind);
	$font-size-information: map-get($font-information, size);

	@return map-has-key($font-size-information, $deviceType);
}

@mixin material-font($kind) {
	font-family: $font-family;
	font-size: material-font-size($kind, 'default');
	font-weight: material-font-weight($kind);

	@if(material-font-has-device-type($kind, 'desktop')) {
		@include desktop-size {
			font-size: material-font-size($kind, 'desktop');
		}
	}
}

