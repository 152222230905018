@import '../../material-design/Colors';
@import '../../material-design/Fonts';

.step-create-document-share {
	&--status {
		fill: material-color(primary, base);
		&.error {
			fill: material-color(accent, base);
		}
	}
}