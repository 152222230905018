@import '../../../material-design/MaterialDesign';

.syn-color {
	color: material-color( primary, base );
}

.syn-background {
	background-color: theme-color( background );
}

.syn-text-color {
	background-color: theme-color( primary-text );
}

.syn-link {
	@extend .syn-color;
	&:active {
		color: material-color( primary, lighter );
	}
}
