
$side-area-width: 56px;
$side-area-avatar-height: 56px;
$side-area-icon-height: 48px;

.material-primary-action-side-area {
	display: flex;
	flex-direction: column;
	min-width: $side-area-width;
	flex-basis: $side-area-width;

	& > div:first-of-type {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&.icon {
			min-height: $side-area-icon-height;
			flex-basis: $side-area-icon-height;
		}
		&.avatar {
			min-height: $side-area-avatar-height;
			flex-basis: $side-area-avatar-height;
		}
	}
}
