@import '../../material-design/MaterialDesign';
@import '../../../constants/GridListConstants.json';
@import '../../Helpers.scss';

.patient-details-document-icons--icon-container {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding-right: #{$tile-margin}px;
	&:last-child {
		padding-right: 0;
	}

	& > .patient-details-document-icons--mark-done-button {
		@include material-font(button);
		height: 32px;
		min-height: 0;
		text-transform: uppercase;
		.touch-target {
			min-height: 0;
		}
	}
}

.patient-details-document-icons--container {
	flex-shrink: 1;
	min-height: 0;
	color: theme-color( secondary-text );

	//background-color: rgba( pink, 0.2 );
}
