@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';
@import '../../material-design/GridSystem';
@import '../../material-design/components/Divider';

$patient-group-spacing: 16px;

.material-card {
	&.patient-group--card {
		@include material-gutter();
		// NOTE: IE11 gets confused about what display property to use.
		//       So explicitly tell him to use flex.
		display: flex;

      	width: 100%;
		overflow: hidden;
		padding-top: 8px;
		padding-bottom: 8px;
		box-sizing: border-box;

		margin-top:  ($patient-group-spacing * 0.5);
		margin-bottom: ($patient-group-spacing * 0.5);

		@include mobile() {
			@include material-elevation(0);
			@include material-divider();
			border-radius: 0;
			margin: 0;
		}

		.patient-group--patient-information {
			display:flex;
			flex-grow: 1;
			align-items:center;
			flex-shrink:1;
			min-width: 0;

			.patient-group--patient-info {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				overflow: hidden;
				padding-right: 8px;

				.patient-name {
					@include material-font(title);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.patient-group--card:hover {
	.patient-group--gender-avatar {
		background-color: material-color(primary, base);
	}

	.patient-group--patient-information {
		color: material-color(primary, base);
	}
}

.patient-group--header {
	display: flex;
	align-items:center;
}

.patient-group--line:hover {
	background-color: theme-color(background);
	border-radius: 3px;
}

.patient-group--document-share {
	border-top: 1px solid theme-color(divider);
}

.patient-group--header + .patient-group--document-share {
	border-top: 0 solid theme-color(divider);
}
