@import '../../material-design/MaterialDesign.scss';

.user-info {
	display: flex;
	align-items: center;
	flex-grow: 1;
	justify-content: flex-start;
	overflow: hidden;

	@include material-font(subhead);

	&--text-container {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		overflow: hidden;

		* {
			padding-right: 8px;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}
