@use "sass:math";

@import "../../../Helpers.scss";

$max-rows: 2;
$max-columns: 2;

.grid-layout {
	position:relative;

	@for $columns from 1 through $max-columns {
		$cell-width: math.div(100%, $columns);
		@for $rows from 1 through $max-rows {
			$cell-height: math.div(100%, $rows);
			&.grid-columns-#{$columns}.grid-rows-#{$rows} {
				& > * {
					width: $cell-width;
					height: $cell-height;

					@for $column from 0 through $columns - 1 {
						@for $row from 0 through $rows - 1 {
							$cell: $row * $columns + $column + 1;
							&:nth-child(#{$cell}) {
								position: absolute;
								top: $row * $cell-height;
								left: $column * $cell-width;
							}
						}
					}
				}
			}
		}
	}
}
