.annotation-label {
	stroke: none;
	fill: currentColor;
	dominant-baseline: middle;
	white-space: pre;
}

.annotation-label--shadow {
	@extend .annotation-label;
	fill: black;
}