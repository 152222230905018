@import '../../../material-design/MaterialDesign';

.privacy-policy-dialog {
	.material-dialog--content {
		@include greater-than-mobile() {
			.material-dialog-header {
				display: none;
			}
			width: 60%;
			height: 80%;
		}
	}
}