@import '../../material-design/MaterialDesign';

.webview-sidebar-footer {
	padding: 4px;
	text-align: center;
	background-color: theme-color(background);
	color: theme-color(secondary-text);
	font-family: $font-family;
	font-weight: material-font-weight(subhead);
	font-size: 13px;
}
