@import '../MaterialDesign';

$slider-height: 2px;
$slider-resting-handle-size: 10px;
$slider-disabled-handle-size: 8px;
$slider-focused-handle-size: 16px;
$slider-resting-opacity: 0.26;
$slider-focused-opacity: 0.38;
$slider-disabled-color: theme-color( disabled-text );
$slider-animation-time: 0.1s;

$slider-handle-touch-area-size: 32px;

.material-slider {
	position: relative;
	display: flex;
	align-items: center;
	transform: translateZ(0);

	height: $slider-height;
	background-color: transparent;
	color: material-color(primary, base);

	&--discrete-handle {
		background: transparent;
		padding: 0;
		margin: 0;
	}

	&--percentage {
		position: relative;
		height: 100%;

		&:not(.disabled) {
			background-color: currentColor;
		}

	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		background-color: currentColor;
		opacity: $slider-resting-opacity;
	}

	&.focused::before {
		opacity: $slider-focused-opacity;
	}

	&--handle {
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);

		height: $slider-resting-handle-size;
		width: $slider-resting-handle-size;
		border-radius: 50%;
		color: inherit;
		background-color: currentColor;
		transition: height $slider-animation-time, width $slider-animation-time;

		&.focused {
			@include desktop-size() {
				height: $slider-focused-handle-size;
				width: $slider-focused-handle-size;
			}
		}

		&.disabled {
			color: $slider-disabled-color;
			width: $slider-disabled-handle-size;
			height: $slider-disabled-handle-size;
		}

		&--touch-area {
			position: absolute;
			left: 50%;
			top: 50%;
			border-radius: 50%;
			opacity: 0.1;
			color: inherit;
			background: currentColor;

			width: $slider-handle-touch-area-size;
			height: $slider-handle-touch-area-size;

			transform: translateY(-50%) translateX(-50%);
			transition: opacity $slider-animation-time;

			&.focused {
				opacity: $slider-focused-opacity;
			}

			&:hover {
				cursor: move;
			}
		}
	}

	&--indicator {
		position: absolute;
		left: 100%;
		bottom: 50%;
		opacity: 0.8;
		visibility: visible;

		transform: translateX(-50%) translateY(-$slider-handle-touch-area-size*0.5);
		transition:
				visibility 0,
				opacity $slider-animation-time*2;

		&.hidden {
			opacity: 0;
			visibility: hidden;
			transition:
					visibility $slider-animation-time*2 step-end,
					opacity $slider-animation-time*2;
		}
	}
}
