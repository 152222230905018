@import '../../MaterialDesign';

.material-secondary-tile-text {
		@include material-font(caption);

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;

	* {
		@include material-font(caption);
	}
}