.syn-iframe-container {
	position: relative;

	&.hidden .syn-iframe--cloned-content {
		visibility: hidden;
	}

	&.hidden {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	iframe {
		position: absolute;
		visibility: hidden;
	}
}
