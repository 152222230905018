@import '../MaterialDesign';
@import '../../../constants/MaterialDialogConstants.json';

$min-height: #{$min-header-height}px;

header.material-dialog-header {
	display: flex;
	align-items: center;
	box-sizing: border-box;

	height: $min-height;
	min-height: $min-height;

	@include mobile-small() {
		@include material-elevation(4, 'bottom');
		@include material-background(primary);
	}

	@include mobile-portrait() {
		height: $standard-mobile-height-increment;
		min-height: $standard-mobile-height-increment;
	}

	&.always-show-close-button {
		.close-button {
			display: block;
			display: initial;
		}
		.title {
			padding-left: 0;
		}
	}

	.close-button {
		display: none;
		@include mobile-small() {
			display: block;
			display: initial;
		}
	}

	.title {
		@include mobile-small() {
			padding-left: 0;
		}
		padding-left: 24px;
	}
}
