@import '../DeviceTypes';

$default-vertical-border-space: 24px;
$mobile-vertical-border-space: 16px;

@mixin material-stepper-padding() {
	padding-left: $default-vertical-border-space;
	padding-right: $default-vertical-border-space;
	@include mobile-small() {
		padding-left: $mobile-vertical-border-space;
		padding-right: $mobile-vertical-border-space;
	}
}

@mixin material-stepper-margin() {
	margin-left: $default-vertical-border-space;
	margin-right: $default-vertical-border-space;
	@include mobile-small() {
		margin-left: $mobile-vertical-border-space;
		margin-right: $mobile-vertical-border-space;
	}
}

.material-stepper {
	// Allow content to scroll if necessary.
	overflow: hidden;
}