@import '../DeviceTypes';
@import './Stepper.scss';

.material-stepper .material-stepper--content {
  overflow-x: hidden;
  overflow-y: auto;

  > * {
    @include material-stepper-padding();
  }
}
