@import '../../../constants/AnnotationConstants.json';

.length-scale--line {
	stroke-linecap: square;
	shape-rendering: crispEdges;

	&--contour {
		stroke: black;
		stroke-width: 1.5px;
	}
}

.length-scale--text {
	stroke: none;

	&--vertical {
		text-anchor: end;
	}

	&--horizontal {
		text-anchor: start;
	}

	&--contour {
		transform: translate(1px, 1px);
		fill: black;
	}
}
