@import '../../material-design/MaterialDesign';

@mixin floating-slider() {
	.dicom-series-toolbar--slider-group {
		flex-grow:0;
	}

	.dicom-series-toolbar--slider {
		position: absolute;
		top: -17px;
		left: 50%;
		width: calc(100% - 50px);
		margin: 0;

		transform: translateX(-50%);

		&::before {
			opacity: 0.38;
		}

		.material-slider--handle--touch-area {
			opacity: 0.32;
		}
	}
}

@mixin toolbar-without-slider() {
	justify-content: space-between;
	position: relative;
	& > * {
		margin: 0;
	}
}

.m21, .m22 {
	@include mobile-small() {
		.dicom-series-toolbar {
			.dicom-series-toolbar--nav-button {
				display: none;
			}
		}
	}

	@include up-to-desktop-size() {
		.dicom-series-toolbar {
			@include toolbar-without-slider();
			@include floating-slider();
		}
	}
}

div.dicom-series-toolbar {
	&--slider {
		color: currentColor;
		flex-grow: 1;
	}
	@include mobile-small() {
		@include toolbar-without-slider();
		@include floating-slider();
	}
}

.dicom-series-toolbar--nav-button.disabled {
	opacity: 0.3;
}

.dicom-series-toolbar--nav-button {
	&.small-margin-right {
		margin-right: 0;
	}
}
