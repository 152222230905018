@import '../../../material-design/Colors';

.status-message {
  	padding: 16px;

	& &--text {
		display: flex;
		align-items: center;

		min-height: 48px;
		white-space: initial;
		text-align: center;
	}

	&--error {
		fill: material-color(accent, base);
	}

	&--success, &--warning {
		fill: material-color(primary, base);
	}
}
