@import '../../../material-design/MaterialDesign';

.material-card.data-table {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.data-table {
	overflow: hidden;

	&--content-area {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		flex-grow: 1;
		overflow: hidden;
	}

	thead th, .table-header .table-head {
		text-align: left;

		@include material-font(body3);
		color: theme-color(primary-text);

		.sort-asc, .sort-desc {
			color: material-color(primary,base);
			display: flex;
			align-items: center;
			fill: currentColor;
		}
	}

	&--footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-grow: 0;

		.disabled .navigation-button {
			opacity: 0.18;
		}
	}

	.row-link {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: block;
	}
}
