@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';
@import '../../material-design/components/Divider';

.patient-details-document-list {
	&--container {
		list-style-type: none;
		padding: 0;

		& > li.patient-details-document-list--entry {
			@include material-divider(1px, 'bottom');

			section {
				padding-bottom: 16px;
				padding-top: 16px;
				display: flex;
				flex-wrap: wrap;
				flex-grow: 1;
				max-width: 100%;
				overflow: hidden;
			}
		}

		& > li.collapsible {
			&--collapsed {
				@include material-divider(1px, 'bottom');
			}
		}

		& > li.hidden {
			display: none
		}

		&--root-node {
			margin: 0 2px 0 0;
		}
	}
}
