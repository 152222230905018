.center-layout--container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	&.flex-direction-column {
		flex-direction: column;
	}

	&.fit-parent {
		width: 100%;
		height: 100%;
	}
}
