@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';

.patient-details-category-header {
	&--row {
		&--category-info {
			flex-grow: 1;
			display: flex;
			overflow: hidden;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			:first-child {
				flex-shrink: 1;
			}

			:nth-child(2) {
				padding-left: 5px;
				flex-shrink: 0;
			}
		}

		&--expand-button {
			width: 35px;
			flex-grow: 0;
			flex-shrink: 0;
			justify-content: center;
		}

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
		}
	}

	&--content {
		display: flex;
		padding: 12px 0;
		width: 100%;
	}
}
