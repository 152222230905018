@import '../../material-design/DeviceTypes';

.tab-search-receiver {
	&--search-field-container {
      	padding-top: 16px;
	}

	.share-document-dialog &--receiver-list {
		padding: 0;
	}

	&--receiver-list {
		position: relative;
		flex-grow: 1;
	}
}