.foldIcon {
	svg {
		fill: currentColor;
		transition: {
			property: transform;
			timing-function: ease-in-out;
			duration: 200ms;
		}
	}

	&--folded svg {
		transform: rotate(0turn);
	}

	&--unfolded svg {
		transform: rotate(0.25turn);
	}
}