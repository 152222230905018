@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';

.patient-details-document-header--row {
	flex-wrap: nowrap;
	min-height: 0;

	@media screen and #{screen-size-between-rule(extra-small, extra-small)} {
		padding-left: 16px;
	}

	&--document-info {
		overflow: hidden;
	}

	&--expand-button {
		width: 48px;
		flex-grow: 0;
		flex-shrink: 0;
	}

	&--checkbox {
		width: 24px;
		margin-right: 4px;
		flex-grow: 0;
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
}

.patient-details-document-header--content {
	display: flex;
}
