@import '../../material-design/MaterialDesign';

.patient-details-page-body {
	&--mobile {
		flex-grow: 1;
		display: block;
		position: relative;

		&.show-viewer {
			.patient-details {
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.viewer-matrix {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&.show-details {
			.patient-details {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.viewer-matrix {
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&--desktop {
		flex-grow: 1;
		display: flex;
		align-items: stretch;

		&.show-viewer, &.show-details {
			.patient-details {
				width: 320px;
				flex-grow: 0;
				flex-shrink: 0;
			}
			.viewer-matrix {
				flex-grow: 1;
			}
		}
	}
}

.print-mode {
	.patient-details-page-body--desktop, .patient-details-page-body--mobile {
		display: flex;
		flex-direction: column;
	}

	.non-diagnostic-usage-note {
		@include material-font(body1);
		margin: 0;
		padding: 1mm 0 0;
	}

	.viewer-matrix {
		position: relative !important;
		flex-grow: 1;
	}
}
