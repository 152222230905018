.js-based-barcode-scanner {
	height: calc(100% - 64px);
	#interactive.viewport {
		position: relative;

		@media (min-width: 960px), (orientation: landscape) {
			height: 100%;
			max-width: 68%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}

		@media (max-width: 960px) and (orientation: portrait) {
			height: 100vw;
			width: 100vw;
		}

		video {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		canvas {
			display: none;
		}
	}

	&--controls {
		flex-grow: 1;
		padding: 10px;
	}
}
