.fhir-viewer {
	&--container {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		background-color: white;
		color: black;
		max-width: 100%;
		max-height: 100%;
		width: 100%;
	}
}

@media print {
	.print-message {
		background-color: white;
		color: black;
	}
}