@import './Variables.scss';
@import './Colors.scss';
@import './Fonts.scss';
@import './ElevationLevels.scss';

@mixin material-background($color: 'primary', $tone: 'base', $opacity: 1.0) {
	background-color: rgba(material-color($color, $tone), $opacity);
	color: material-foreground-color($color, $tone);
	fill: material-foreground-color($color, $tone);

	@media print {
		background-color: transparent;
		color: black;
		fill: black;
	}
}
