@import '../MaterialDesign';

.material-dialog {
	&--container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1em;
		height: 100%;
		width: 100%;
		box-sizing: border-box;

		@include mobile-small() {
			flex-grow: 1;
			padding: 0;
		}
	}

	&--content {
		position: relative;
		background-color: theme-color(paper);
		max-height: 100%;
		max-width: 100%;
		@include material-elevation(24);

		@include mobile-small() {
			flex-grow: 1;
			width: 100%;
			height: 100%;

			@include material-elevation(0);
		}
	}
}
