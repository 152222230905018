@import '../../material-design/MaterialDesign';

$mobile-link-height: 48px;
$desktop-link-height: 48px;

.subtext-link {

	cursor: pointer;
	color: material-color(primary, base);
	text-decoration: underline;
	margin-top: 4px;

	&:link, &:active {
		color: material-color(primary, base);
	}

	&:hover {
		color: theme-color(secondary-text);
	}
}