@import '../../material-design/Colors';
@import '../../material-design/DeviceTypes';
@import '../../material-design/Variables';

@import '../../Helpers.scss';
@import '../../../constants/MaterialDialogConstants.json';
@import '../../material-design/components/Divider';

$tab-height: 48;
$min-height-with-tab-bar: #{$tab-height + $min-header-height}px;
$min-mobile-height-with-tab-bar: #{$tab-height + $standard-mobile-height-increment};

.share-document-dialog {
	&--stepper {
		flex-grow: 1;
		.stepper-content {
			padding-top: 16px;
		}
	}

	&--tabbar {
		width: 100%;
	}

	.material-dialog--content {
		height: 10px; // Layout fix for IE11

		@include fixed-width-limits(40vw);
		@include fixed-height-limits(60vh);

		@include up-to-desktop-size() {
			@include fixed-width-limits(60vw);
			@include fixed-height-limits(60vh);
		}

		@include mobile() {
			@include fixed-width-limits(70vw);
		}

		@include mobile-small() {
			height: 100%; // Disable fix for IE11 on mobile-small devices.
			max-width: none;
			max-height: none;
		}
	}

	header {
		position: relative;

		.title {
			flex-grow: 1;
		}

		&.with-tab-bar {
			@include greater-than-mobile-small() {
				@include material-divider();
			}

			flex-wrap: wrap;
			height: $min-height-with-tab-bar;
			min-height: $min-height-with-tab-bar;

			@include mobile-portrait() {
				height: $min-mobile-height-with-tab-bar;
				min-height: $min-mobile-height-with-tab-bar;
			}

			.title {
				height: #{$min-header-height}px;
				@include mobile-portrait() {
					height: $standard-mobile-height-increment;
				}
			}
		}
	}
}
