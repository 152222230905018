@import "~@fontsource/roboto/300.css";
@import "~@fontsource/roboto/400.css";
@import "~@fontsource/roboto/500.css";
@import "~@fontsource/roboto/700.css";
@import "~@fontsource/roboto-mono/400.css";

@import './SynedraColors.scss';

@import './material-design/MaterialDesign.scss';

h1, h2, h3, h4, h5, h6, p, pre {
	margin:0;
	padding:0;
}

html {
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	-ms-touch-action: none;
	touch-action: none;
	@include material-font(body1);
	background-color: map-get($current-material-theme, background);
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	text-size-adjust: none;
}

body {
	* {
		outline: none;
		overscroll-behavior: none;
	}
}

#content {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

@page {
	size: A4 portrait;
	margin: 9mm 10mm;  /* this affects the margin in the printer settings */
}

.print-mode {
	body.print-mode {
		background-color: transparent !important;
	}
}

html.print-mode, html.print-mode body {
	width: 190mm !important;
	height: 261mm  !important;
	print-color-adjust: exact;

	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	overflow: hidden !important;
	position: relative;
}

b, strong {
	font-weight: map-get($font-weights, "bold");
}
