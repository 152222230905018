@import '../../../material-design/MaterialDesign';
@import '../../../../constants/AnnotationConstants.json';
@import '../../../material-design/Colors';

.annotation-element {
	fill: none;
	stroke: currentColor;
	stroke-width: $annotation-line-width;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.annotation-element--contour {
	@extend .annotation-element;
	stroke: black;
	stroke-width: $annotation-line-width + 1;
}

.hover, .annotation-active {
	.annotation, 
	.annotation-label, 
	.annotation-element, 
	.marker-dot,  
	.annotation-icon--icon {
		color: material-color(primary, accent);
	}
	@include mobile() {
		.annotation-visible-touch-area {
			opacity: $annotation-touch-opacity;
		}
		
	}
}

.annotation-visible-touch-area {
	@media print {
		display: none;
	}
	transition: opacity 72ms $swift-ease-out-timing-function;
	opacity: 0;
}
