@import '../../viewer/components/ViewerColors.scss';

.preview-tile-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $viewer-foreground-color;

	background: transparent;

	&--hang-matrix {
		flex-grow: 1;
	}
}