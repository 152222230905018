@import '../../../material-design/MaterialDesign';

div.popup-help-button--popup {
	max-height: calc(100% - #{$standard-mobile-height-increment} - 16px);
	@include greater-than-mobile-small() {
		max-width: 50%;
	}

	.help-component-container {
		padding: 16px;
		margin: 0;
		width: 100%;
		height: auto;

		box-sizing: border-box;
		overflow: auto;
	}
}
