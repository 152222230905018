@import '../../material-design/MaterialDesign';
@import '../../material-design/DeviceTypes';

.archive-documents-browser-toolbar {
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	text-align: right;
	min-height: 49px;
	@include up-to-mobile-small() {
		background-color: #FAFAFA;
	}

	.icon-button {
		color: material-color(primary, base);
	}
}