@import '../../../material-design/MaterialDesign';
@import '../../../material-design/components/Divider';

$details-toggle-hover-color: rgba( #999, 0.2);

.unhandled-error-list-item {
	@include material-divider(1px, bottom);

	padding-top: 10px;
	padding-bottom: 10px;

	&:last-child {
		border: none;
	}

	.details {
		overflow: hidden;
		transition: max-height 0.28s ease-in-out, padding 0.28s ease-in-out;
		min-height: 0;

		padding-top: 10px;
		padding-bottom: 10px;

		background-color: theme-color(background);

		&.collapsed {
			max-height: 0;
			padding: 0;
		}
	}

	.details-toggle-controls {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-grow: 1;
		min-height: 42px;

		cursor: pointer;

		&:hover {
			background-color: $details-toggle-hover-color;
		}
	}
}
