@import '../GridSystem';

.material-grid-row {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
	min-width: 0;

	&.no-wrap {
		flex-wrap: nowrap;
	}

	&.no-shrink {
		flex-shrink: 0;
	}

	@include material-grid-row-padding;
}
