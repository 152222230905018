@import '../../material-design/Colors';
@import '../../material-design/ElevationLevels';
@import '../../../constants/PdfViewConstants.json';

.pdf-view--container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: theme-color(background);
	display: flex;
	align-items: center;
	justify-content: stretch;
}

.pdf-view--row-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	box-sizing: border-box;
	padding: #{$pdf-page-padding}px 0 #{$pdf-page-padding}px #{$pdf-page-padding}px;

	> * {
		flex-grow: 0;
		flex-shrink: 0;
		background-color: theme-color(paper);
		@include material-elevation(3);
	}
}

