@import '../../material-design/MaterialDesign';

.patient-details-loader-error {
	padding: 16px;
	flex-grow: 0;

	.message {
		@include material-font(body1);
		color: theme-color(primary-text);
		padding-top: 10px;
		padding-right: 8px;
	}

	.links-container {
		padding-top: 16px;
		color: material-color( primary, base );
		& > *:active {
			color: material-color( accent, base );
		}
		& > * {
			text-decoration: underline;
		}
	}
}
