@import '../../MaterialDesign';
@import '../../../Helpers.scss';

$header-height: 64px;

.material-card-header {
	@include fixed-height($header-height);
	color: theme-color(primary-text);
	padding-left: 24px;
	display: flex;
	align-items: center;
}
