.image-viewer--container {
	text-align: center;
	position: relative;
	max-width: 100%;
	max-height: 100%;
	flex-grow: 1;

	> * {
		position: absolute;
		left: 0;
		top: 0;
	}
}

.image-viewer-active-tool-pan {
	cursor: move;
}

.image-viewer-active-tool-window {
	// NOTE: the double specification of the cursor property is necessary,
	// to get the correct hotspots within the cursors!
	cursor: url(~images/cursors/window-tool.cur), auto; // Supported by: Chrome, IE, Edge
	cursor: url(~images/cursors/window-tool.cur), url(~images/cursors/window-tool.png) 15 15, auto; // Supported By: FF, Safari
}

.image-viewer-active-tool-zoom {
	// NOTE: the double specification of the cursor property is necessary,
	// to get the correct hotspots within the cursors!
	cursor: url(~images/cursors/zoom-tool.cur), auto; // Supported by: Chrome, IE, Edge
	cursor: url(~images/cursors/zoom-tool.cur), url(~images/cursors/zoom-tool.png) 15 15, auto; // Supported By: FF, Safari
}
