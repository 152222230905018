@import '../../Helpers.scss';

.application-main-area {
	position: relative;
	overflow: hidden;

	@include fixed-width(100vw);
	@include fixed-height(100%);
}

.print-mode .application-main-area {
	@include fixed-width(100%);
	@include fixed-height(100%);
}
