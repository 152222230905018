@import '../../../material-design/MaterialDesign';

.non-diagnostic-usage-message--help-link {
	color: material-color( primary, base );
	&:hover {
		color: material-color( primary, lighter );
	}
	&:active {
		color: material-color( accent, base );
	}
}