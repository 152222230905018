@import '../../material-design/DeviceTypes';

@import '../../material-design/components/Stepper';

.step-write-message {
  .input-field {
    // NOTE: TextField does not like to be padded, so use margin instead.
    padding: 0;
    @include material-stepper-margin();
  }

	.share-receiver-list-item {
		margin-bottom: 16px;
	}
}
