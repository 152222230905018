.active-tool-pan {
	cursor: move;
}

.active-tool-window {
	// NOTE: the double specification of the cursor property is necessary,
	// to get the correct hotspots within the cursors!
	cursor: url(~images/cursors/window-tool.cur), auto; // Supported by: Chrome, IE, Edge
	cursor: url(~images/cursors/window-tool.cur), url(~images/cursors/window-tool.png) 15 15, auto; // Supported By: FF, Safari
}

.active-tool-zoom {
	// NOTE: the double specification of the cursor property is necessary,
	// to get the correct hotspots within the cursors!
	cursor: url(~images/cursors/zoom-tool.cur), auto; // Supported by: Chrome, IE, Edge
	cursor: url(~images/cursors/zoom-tool.cur), url(~images/cursors/zoom-tool.png) 15 15, auto; // Supported By: FF, Safari
}
