@import '../../material-design/MaterialDesign';
@import '../../Helpers.scss';
@import '../../material-design/DeviceTypes';

.archive-documents-dialog {
	.material-dialog--content {
		height: 10px; // Layout fix for IE11

		@include fixed-width-limits(40vw);
		@include fixed-height-limits(80vh);

		@include up-to-desktop-size() {
			@include fixed-width-limits(60vw);
			@include fixed-height-limits(80vh);
		}

		@include mobile() {
			@include fixed-width-limits(70vw);
		}

		@include mobile-small() {
			height: 100%; // Disable fix for IE11 on mobile-small devices.
			max-width: none;
			max-height: none;
		}
	}
}
