@import '../MaterialDesign';

.material-title {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	*, h6 *, h6 {
		margin-right: .2em;
	}
}
